#tree_view {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}

#tree_view .node {
  cursor: pointer;
  text-anchor: start;
}

#tree_view .node rect {
  stroke: gray;
  stroke-width: 1.5px;
}

#tree_view .node text {
  font: 12px sans-serif;
}

#tree_view .link,
#tree_view .mpLink {
  fill: none;
  stroke: #ccc;
}
